<template>
  <div>
  <v-bottom-navigation

    :value="value"
    color="primary"
    app
    grow

  >
    <v-btn>
      <span>Recents</span>

      <v-icon>mdi-history</v-icon>
    </v-btn>

    <v-btn>
      <span>Favorites</span>

      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn>
      <span>Favorites</span>

      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn>
      <span>Nearby</span>

      <v-icon>mdi-map-marker</v-icon>
    </v-btn>
  </v-bottom-navigation>
  <div class="botblocker"></div>
</div>

</template>

<script>
  export default {
    data: () => ({ value: 1 }),
  }
</script>

<style lang="scss" scoped>
.botblocker{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  height: 30px;
}
.v-item-group.v-bottom-navigation{

  box-shadow: none;
  border-top: 1px solid #EEE;

  box-sizing:border-box;

  bottom: 30px;
}
</style>